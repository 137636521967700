<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import axios from "axios";
// import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
// import DatePicker from "vue2-datepicker";
// import branchComponent from "@/components/branchComponent.vue";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "ตั้งค่าโซเชียลมีเดีย",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, Multiselect },
  //   Multiselect, DatePicker
  data() {
    return {
      // tableData: tableData, btnComponent
      dismissSecs: 3,
      dismissCountDown: 0,
      resalert: "",
      title: "ตั้งค่าโซเชียลมีเดีย",
      items: [
        {
          text: "ลูกค้าสัมพันธ์",
          active: true,
        },
        {
          text: "ตั้งค่าโซเชียลมีเดีย",
          active: false,
        },
      ],
      form: {
        branchNameTh: "",
      },
      apiKey: "",
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      totalRecords: 0,
      optionBranch: [],
      rowData: [],
      filter: [],
      branchName: "",
      branchIdSearch: "",
      userBranch: "",
      filterOn: [],
      rowsPO: [],
      sortBy: "age",
      sortDesc: false,
      localData: [],
      submitform: false,
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      rowDataIndex: [],
      labelText: "",
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "pageCode",
          sortable: false,
          label: "รหัสเพจ",
        },
        {
          key: "pageName",
          sortable: false,
          label: "ชื่อเพจ",
        },
        {
          key: "platform",
          sortable: false,
          label: "แพลตฟอร์ม",
        },
        {
          key: "pageAccessToken",
          sortable: false,
          label: "pageAccessToken",
        },
        {
          key: "branchName",
          sortable: false,
          label: "สาขา",
        },
      ],
      overlayFlag: false,
    };
  },
  validations: {
    apiKey: {
      required,
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });

    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
  },
  created() {
    this.getData();
    // this.select()
  },
  methods: {
    showApi(pageAccessToken) {
      Swal.fire(pageAccessToken);
    },
    updateBranch(id, branchId) {
      this.putBranch(id, branchId ? branchId.branchId : "");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },
    showAlert(response) {
      this.dismissCountDown = this.dismissSecs;
      this.resalert = response;
    },
    Form(apiKey) {
      localStorage.setItem('apiKey', apiKey);
      this.$v.$touch();
      this.submitform = true;
      if (this.$v.$invalid != true) {
        this.getAllPages(apiKey);
      }
    },
    getAllPages(token) {
      this.overlayFlag = true;
      if (token) {
        axios
          .get(`${appConfig.api.baseURLsmms}/api/all-pages`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((response) => {
            this.rowData = response.data.pages;
            this.labelText = response.data.platform;
            this.rowData = this.rowData.map((item) => {
              const data = this.rowDataIndex.filter(
                (x) => x.pageSmmsId === item.pageId
              );

              data["pageId"] = item.pageId;
              data["pageCode"] = item.pageCode;
              data["pageName"] = item.pageName;
              data["pageAccessToken"] = item.pageAccessToken;
              data["platform"] = item.platform;
              return data;
            });
            this.rowData.forEach((e) => {
              if (e.pageId) {
                e.forEach((i) => {
                  e.branchNameTh = {
                    nameTh: i.branchNameTh,
                    branchId: i.branchId,
                  };
                });
              }
            });
            this.putApiKey();
            // if (this.rowData.length !== this.rowDataIndex.length) {
            this.postPageAll(this.rowData);
            this.getData();
            // }
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(err.response),
              appConfig.swal.type.error
            );
          })
          .then(() => {
            this.overlayFlag = false;
          });
      }
    },
    getData() {
      this.overlayFlag = true;
      // console.log(token);

      useNetw
        .get("api/social-media-api", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
          },
        })
        .then((response) => {
          this.rowDataIndex = response.data.data;
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    putApiKey: function () {
      this.overlayFlag = true;

      useNetw
        .put("api/social-media-api/update-api-key", {
          smmsApiKey: this.apiKey,
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    putBranch: function (id, branchId) {
      this.overlayFlag = true;

      useNetw
        .put("api/social-media-api/update-branch", {
          pageId: id,
          branchId: branchId,
        })
        .then((response) => {
          this.showAlert(response.data.message);
          this.getData();
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.message +
                "<br>" +
                err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    postPageAll: function (data) {
      this.overlayFlag = true;
      const pageId = [];
      const pageCode = [];
      const platform = [];
      const pageAccessToken = [];
      const nameTh = [];
      data.forEach((ele) => {
        pageId.push(ele.pageId);
        pageCode.push(ele.pageCode);
        platform.push(ele.platform);
        pageAccessToken.push(ele.pageAccessToken);
        nameTh.push(ele.pageName);
      });

      useNetw
        .post("api/social-media-api/store", {
          pageSmmsId: pageId,
          pageCode: pageCode,
          platform: platform,
          pageAccessToken: pageAccessToken,
          nameTh: nameTh,
        })
        .then((response) => {
          this.showAlert(response.data.message);
        })
        .catch((err) => {
          this.showAlert(err.response.data.message);
          // Swal.fire(
          //   appConfig.swal.title.error,
          //   JSON.stringify(
          //     err.message +
          //       "<br>" +
          //       err.response.data.message +
          //       "<br>" +
          //       err.response.data.validationMessage
          //   ),
          //   appConfig.swal.type.error
          // );
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
  },
  middleware: "authentication",
};
</script>
<style scoped>
@media only screen and (min-width: 500px) {
  .alert-fixed {
    position: fixed;
    top: 50px;
    left: 65%;
    width: 30%;
    z-index: 9999;
    border-radius: 0px;
  }
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-alert
          class="alert-fixed fixed-top rounded-1 m-4 bg-light alert-front"
          :show="dismissCountDown"
          variant="success"
          dismissible
          @dismissed="dismissCountDown = 0"
          @dismiss-count-down="countDownChanged"
        >
          <p class="float-end">{{ dismissCountDown }} วินาที...</p>
          <p>แจ้งเตือน</p>
          <hr />
          <p>{{ resalert }}</p>
        </b-alert>
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div
                          class="
                            col-3 col-sm-2 col-md-2 col-lg-1
                            align-self-center
                          "
                        >
                          <div class="mb-3 position-relative">
                            <label>API Key</label>
                          </div>
                        </div>
                        <div class="col-9 col-sm-6 col-md-7 col-lg-6">
                          <div class="mb-3 position-relative">
                            <b-form-input
                              v-model="apiKey"
                              type="search"
                              class="form-control"
                              :class="{
                                'is-invalid': submitform && $v.apiKey.$error,
                              }"
                            ></b-form-input>
                            <div
                              v-if="submitform && $v.apiKey.$error"
                              class="invalid-tooltip"
                            >
                              <span v-if="!$v.apiKey.required">{{
                                error
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 col-md-3 col-lg-5">
                          <div class="mb-3 position-relative">
                            <b-button
                              class="btn"
                              variant="primary"
                              type="submit"
                              @click="Form(apiKey)"
                            >
                              ปรับปรุงรายการเพจ
                            </b-button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            &nbsp;
                            <!-- <b-button
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              :to="{ name: 'create-credit-inquire' }"
                            >
                              <i class="uil-plus"></i>
                              สร้างบันทึกผลข้อมูลเครดิต
                            </b-button> -->
                            <!-- <btnComponent
                              @click="handleSearch"
                              changeStyle="search"
                            >
                            </btnComponent> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
                <div class="table-responsive mb-0">
                  <table class="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">ลำดับ</th>
                        <th scope="col">รหัสเพจ</th>
                        <th scope="col">ชื่อเพจ</th>
                        <th scope="col">แพลตฟอร์ม</th>
                        <th scope="col">pageAccessToken</th>
                        <th scope="col">สาขา</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in rowData" :key="index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>{{ item.pageCode }}</td>
                        <td>{{ item.pageName }}</td>
                        <td>
                          <span v-if="item.platform == 'FB'">{{
                            labelText.FB
                          }}</span>
                          <span v-if="item.platform == 'Line'">{{
                            labelText.Line
                          }}</span>
                        </td>
                        <td class="text-center">
                          <b-button
                            class="btn-sm"
                            variant="danger"
                            @click="showApi(item.pageAccessToken)"
                          >
                            แสดง Token
                          </b-button>
                        </td>
                        <td>
                          <multiselect
                            v-model="item.branchNameTh"
                            :options="localData"
                            label="nameTh"
                            track-by="branchId"
                            :show-labels="false"
                            @input="
                              updateBranch(item[0].pageId, item.branchNameTh)
                            "
                          >
                          </multiselect>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row mt-3">
                  <span
                    v-if="this.rowData.length == 0"
                    class="text-center font-size-16"
                    >ไม่พบรายการ</span
                  >
                </div>
              </b-skeleton-wrapper>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
